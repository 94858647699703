import { gql, useMutation } from "@apollo/client"
import React from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { ActionsDropdownMenuItem, ActionsDropdownMenuPanel } from "./Workspace"
import { ReactComponent as ResendIcon } from "../assets/icons/resend.svg"
import { ReactComponent as XIcon } from "../assets/icons/x-gray.svg"
import Badge from "../common/Badge"
import DropdownMenu from "../common/DropdownMenu"
import { ReactComponent as MoreIcon } from "../common/images/more-horizontal.svg"
import { Table } from "../common/members/Table"

import {
  WorkspaceDocument,
  WorkspaceFragment,
  WorkspaceInviteResendMutation,
  WorkspaceInviteResendMutationVariables,
  WorkspaceInviteRevokeMutation,
  WorkspaceInviteRevokeMutationVariables,
} from "@/types/graphql-types"

interface WorkspacePendingProps {
  workspace: WorkspaceFragment
}

const WorkspacePending = ({ workspace }: WorkspacePendingProps) => {
  const [revokeWorkspaceInvite] = useMutation<
    WorkspaceInviteRevokeMutation,
    WorkspaceInviteRevokeMutationVariables
  >(WORKSPACE_INVITE_REVOKE)
  const [resendWorkspaceInvite] = useMutation<
    WorkspaceInviteResendMutation,
    WorkspaceInviteResendMutationVariables
  >(WORKSPACE_INVITE_RESEND)
  const { pendingInvites } = workspace

  const resendInvite = async (id: string, email: string | null) => {
    const res = await resendWorkspaceInvite({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: WorkspaceDocument,
          variables: { id: workspace.id },
        },
      ],
    })

    if (res.data?.workspaceInviteResend.ok) {
      toast.success(`Invite re-sent to ${email || "member"}`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      if (res.data?.workspaceInviteResend.error) {
        toast.error(res.data.workspaceInviteResend.error)
      } else {
        toast.error("An unknown error occurred.")
      }
    }
  }

  const revokeInvite = async (id: string, email: string | null) => {
    const res = await revokeWorkspaceInvite({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: WorkspaceDocument,
          variables: { id: workspace.id },
        },
      ],
    })
    if (res.data?.workspaceInviteRevoke.ok) {
      toast.success(`Invite for ${email || "member"} revoked`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      alert("An unexpected error occurred.")
    }
  }

  const ActionsDropdown = ({ id, email, onlyDisplayOn }: IActionsDropdown) => {
    const Container =
      onlyDisplayOn === "mobile"
        ? AtionsDropdownContainerForMobile
        : React.Fragment

    return (
      <Container>
        <DropdownMenu
          tw="absolute"
          trigger={
            <MoreIcon
              fill="black"
              css={[onlyDisplayOn === "desktop" && tw`hidden! lg:block!`]}
            />
          }
        >
          <ActionsDropdownMenuPanel>
            <ActionsDropdownMenuItem onClick={() => resendInvite(id, email)}>
              <ResendIcon />
              Re-send invite
            </ActionsDropdownMenuItem>
            <ActionsDropdownMenuItem
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to revoke this invite?")
                ) {
                  revokeInvite(id, email)
                }
              }}
            >
              <XIcon />
              Revoke invite
            </ActionsDropdownMenuItem>
          </ActionsDropdownMenuPanel>
        </DropdownMenu>
      </Container>
    )
  }

  return (
    <div tw="py-9 px-6 lg:px-12">
      <Table
        headers={["Email", "Invited By", "Status"]}
        gridTemplateColumns="3fr 3fr 1fr 1fr"
      >
        <>
          {pendingInvites.length === 0 ? (
            <tr>
              <td colSpan={4}>
                <div tw="text-gray-500">No invites pending.</div>
              </td>
            </tr>
          ) : null}
          {pendingInvites.map(
            ({ email, id, isExpired, senderName, senderEmail }) => (
              <tr key={email}>
                <td className="data-hj-suppress ph-no-capture fs-exclude">
                  {email}
                </td>
                <td
                  className="data-hj-suppress ph-no-capture fs-exclude"
                  tw="flex flex-col"
                  // The style prop is used because CSS attribute was not being applied with other
                  // methods
                  style={{ alignItems: "flex-start" }}
                >
                  <WorkspaceMemberName>{senderName}</WorkspaceMemberName>
                  <WorkspaceMemberRole>{senderEmail}</WorkspaceMemberRole>
                </td>
                <td>
                  <Badge variant={isExpired ? "gray" : "blue"}>
                    {isExpired ? "Expired" : "Pending"}
                  </Badge>
                </td>
                <td>
                  <ActionsDropdown
                    id={id}
                    email={email}
                    onlyDisplayOn="desktop"
                  />
                </td>
              </tr>
            ),
          )}
        </>
      </Table>
    </div>
  )
}

const WorkspaceMemberName = tw.div`font-medium text-base`
const WorkspaceMemberRole = tw.div`text-gray-500`

const WORKSPACE_INVITE_RESEND = gql`
  mutation WorkspaceInviteResend($id: ID!) {
    workspaceInviteResend(id: $id) {
      ok
      error
    }
  }
`

const WORKSPACE_INVITE_REVOKE = gql`
  mutation WorkspaceInviteRevoke($id: ID!) {
    workspaceInviteRevoke(id: $id) {
      ok
    }
  }
`

interface IActionsDropdown {
  id: string
  email: string
  onlyDisplayOn: "mobile" | "desktop"
}

const AtionsDropdownContainerForMobile = styled.span`
  ${tw`absolute right-0`}

  nav {
    ${tw`right-5 top-2 lg:hidden`}
  }

  ul {
    ${tw`-mt-4`}
  }
`

export default WorkspacePending
