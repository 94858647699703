import { gql, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import tw, { styled } from "twin.macro"

import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as XIcon } from "../assets/icons/x-gray.svg"
import WorkspaceInviteAccessDropdown from "../common/components/WorkspaceInviteAccessDropdown"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import Input from "../common/Input"
import LearnAboutFullVsLimitedModalButton from "../common/members/LearnAboutFullVsLimitedModalButton"
import { GraySmallButton } from "../common/SmallButton"
import { generateUUID } from "../common/utilities"
import WorkspaceInvitePromo from "../signUp/components/WorkspaceInvitePromo"

import { track } from "@/common/analytics"
import {
  OrganizationMembershipAccess,
  WorkspaceDocument,
  WorkspaceFragment,
  WorkspaceInviteCreateInput,
  WorkspaceInviteCreateMutation,
  WorkspaceInviteCreateMutationVariables,
} from "@/types/graphql-types"

interface WorkspaceInviteFormValues {
  workspaceInvites: WorkspaceInviteCreateInput[]
  access: OrganizationMembershipAccess
}

interface WorkspaceInviteMemberFormProps {
  workspace: WorkspaceFragment
  visible: boolean
  onClose: () => void
  isFullOrganizationMember: boolean
}

const WorkspaceInviteMemberForm = ({
  workspace,
  visible,
  onClose,
  isFullOrganizationMember,
}: WorkspaceInviteMemberFormProps) => {
  const [currentUser] = useGlobalState("user")
  const defaultWorkspaceInviteValue = Object.freeze({ email: "" })
  const [workspaceInvitesCreate, { loading }] = useMutation<
    WorkspaceInviteCreateMutation,
    WorkspaceInviteCreateMutationVariables
  >(WORKSPACE_INVITE_CREATE)
  const {
    control,
    handleSubmit,
    register,
    setError,
    clearErrors,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<WorkspaceInviteFormValues>({
    defaultValues: {
      workspaceInvites: Array(3).fill(defaultWorkspaceInviteValue),
      access: isFullOrganizationMember
        ? OrganizationMembershipAccess.full
        : OrganizationMembershipAccess.limited,
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: "workspaceInvites",
  })

  useEffect(() => {
    if (visible) {
      setFocus("workspaceInvites.0.email")
    }
  }, [visible, setFocus])

  // Controls whether the "successfully invited X members" banner is displayed (number) or not (null).
  const [invitedMemberCount, setInvitedMemberCount] = useState<number | null>(
    null,
  )

  const onSubmit: SubmitHandler<WorkspaceInviteFormValues> = async ({
    workspaceInvites,
    access,
  }) => {
    track("Business - Workspaces - Members - Workspace Invite - Click Invite")

    const res = await workspaceInvitesCreate({
      variables: { workspaceId: workspace.id, workspaceInvites, access },
      refetchQueries: [
        {
          query: WorkspaceDocument,
          variables: { id: workspace.id },
        },
      ],
    })

    const resData = res.data?.workspaceInviteCreate
    if (resData?.ok) {
      // Display the success message.
      if (resData.invitedCount && resData.invitedCount > 0) {
        setInvitedMemberCount(resData.invitedCount)
      } else {
        // Otherwise, clear banner
        setInvitedMemberCount(null)
      }

      if (resData.errors && resData.errors.length > 0) {
        // Replace the array inputs with the errored inputs.
        reset({
          workspaceInvites: resData.errors.map((error) => {
            return {
              email: error.email || "",
            }
          }),
        })

        // Clear the existing errors, then set new errors by index.
        // We use the email as the 'single error field' for the row.
        for (let i = 0; i < resData.errors.length; i++) {
          const err = resData.errors[i]
          if (err.error) {
            setError(
              `workspaceInvites.${i}.email` as "workspaceInvites.0.email",
              {
                type: "manual",
                message: err.error,
              },
            )
          }
        }
      } else {
        // No errors.
        // Reset the form to the original state.
        clearErrors()
        reset()
      }
    } else {
      alert("An unknown error occurred.")
    }
  }

  const add5Rows = () => {
    append(Array(5).fill(defaultWorkspaceInviteValue), { shouldFocus: false })
  }

  return (
    <InviteFormVisibilityContainer visible={visible}>
      <InviteMemberFormContainer id="invite-member-form">
        <div tw="relative flex w-full">
          <div tw="-top-2 -right-6 absolute">
            <button
              tw="p-4 rounded-full focus:outline-none focus-visible:bg-gray-100 hover:bg-gray-100 active:bg-gray-200 transition-colors"
              onClick={onClose}
            >
              <XIcon />
            </button>
          </div>
          <div tw="md:mr-24 w-full">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              {invitedMemberCount && invitedMemberCount > 0 ? (
                <div tw="mb-6 mr-6 lg:mr-0 bg-green-500 text-white py-3 px-5 rounded-lg">
                  {invitedMemberCount} invite
                  {invitedMemberCount === 1 ? "" : "s"} sent.
                </div>
              ) : null}
              <InviteTitle>Invite members to {workspace.name}</InviteTitle>
              <p tw="mb-6">
                Workspace members can send and view gifts in a single workspace.
              </p>

              {!currentUser?.hasPaidPlan && (
                <div tw="my-8 w-full">
                  <WorkspaceInvitePromo shrink />
                </div>
              )}

              {fields.map((field, index) => (
                <InviteInputRow key={field.id}>
                  <InviteInputRowInputs>
                    <Input
                      tw="max-w-sm"
                      {...register(
                        `workspaceInvites.${index}.email` as "workspaceInvites.0.email",
                      )}
                      placeholder="Work email"
                      type="text"
                      // Disable autocomplete, at least the first time...
                      autoComplete={generateUUID()}
                      className="data-hj-suppress ph-no-capture fs-exclude"
                    />
                  </InviteInputRowInputs>
                  {errors.workspaceInvites &&
                    errors.workspaceInvites[index] &&
                    errors.workspaceInvites[index].email?.message && (
                      <InviteInputRowErrors>
                        {errors.workspaceInvites[index].email?.message}
                      </InviteInputRowErrors>
                    )}
                </InviteInputRow>
              ))}
              {workspace.isTeamPlan && isFullOrganizationMember && (
                <div tw="mt-6 mb-2 flex flex-col sm:flex-row items-start sm:items-center">
                  <WorkspaceInviteAccessDropdown {...register("access")} />
                  <LearnAboutFullVsLimitedModalButton tw="mt-4 sm:mt-0 ml-2" />
                </div>
              )}
              <div tw="flex items-center pt-4">
                <GradientButton disabled={loading} tw="inline-flex gap-10 mr-6">
                  Invite <ArrowRight />
                </GradientButton>
                <GraySmallButton
                  onClick={() => add5Rows()}
                  label="Add 5 rows"
                  hideArrow={true}
                  tw="px-2.5 py-1"
                  type="button"
                />
              </div>
            </form>
          </div>
        </div>
      </InviteMemberFormContainer>
    </InviteFormVisibilityContainer>
  )
}

interface InviteFormVisibilityContainerProps {
  visible: boolean
}

const InviteFormVisibilityContainer = styled.div<InviteFormVisibilityContainerProps>`
  overflow: hidden;
  height: ${(props) => (props.visible ? "auto" : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`

const InviteMemberFormContainer = styled.div`
  ${tw`bg-gray-050 flex mb-12 p-9 px-6 lg:px-12 text-gray-500`};
`

const InviteTitle = tw.h3`font-medium mb-3 text-black text-xl leading-5`
const InviteInputRow = tw.div`mb-4 py-1 lg:py-0`
const InviteInputRowInputs = tw.div`grid grid-cols-1 gap-3 mb-1`
const InviteInputRowErrors = tw.div`bg-gray-200 py-1 px-3 rounded-lg mb-1`

const WORKSPACE_INVITE_CREATE = gql`
  mutation WorkspaceInviteCreate(
    $workspaceId: ID!
    $workspaceInvites: [WorkspaceInviteCreateInput!]!
    $access: OrganizationMembershipAccess!
  ) {
    workspaceInviteCreate(
      workspaceId: $workspaceId
      workspaceInvites: $workspaceInvites
      access: $access
    ) {
      ok
      errors {
        email
        error
      }
      invitedCount
    }
  }
`

export default WorkspaceInviteMemberForm
