import { gql, useMutation } from "@apollo/client"
import { cloneDeep, isEqual, sumBy } from "lodash-es"
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react"
import {
  SubmitHandler,
  UseFormRegister,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { gradientBg } from "@/assets/cdnAssets"
import { ReactComponent as ChevronRight } from "@/assets/icons/chevron-right.svg"
import Logo from "@/assets/images/logo.svg"
import { track, useScreen } from "@/common/analytics"
import Button from "@/common/Button"
import { useGlobalState } from "@/common/GlobalState"
import GradientBackground from "@/common/GradientBackground"
import { gtmEvent } from "@/common/gtm"
import { useCurrentWorkspace } from "@/common/hooks"
import { useGiftCart } from "@/common/hooks/giftData"
import { generateRealmPath } from "@/common/realm"
import staticAssetUrl from "@/common/staticAssetUrl"
import { TextInput } from "@/common/TextInputField"
import {
  ONBOARDING_SHOW_WELCOME_WITH_CART_LOCAL_STORAGE_KEY,
  ONBOARDING_SHOW_WELCOME_WITH_CART_TRUE_VALUE,
} from "@/send/components/UserHadCartAtOnboarding"
import Container from "@/sites/App/Container"
import {
  PostSignup_WorkspaceInviteCreateMutation,
  PostSignup_WorkspaceInviteCreateMutationVariables,
  WorkspaceDocument,
  WorkspaceInviteCreateInput,
} from "@/types/graphql-types"

interface WorkspaceInviteFormValues {
  workspaceInvites: WorkspaceInviteCreateInput[]
}

export default function InviteV2() {
  const [currentUser] = useGlobalState("user")
  const { currentWorkspace } = useCurrentWorkspace()
  const { cartQuantity } = useGiftCart()

  useScreen("Business - Post-Signup - Invite")

  const [, setIsBusinessWelcomeModalOpen] = useGlobalState(
    "isBusinessWelcomeModalOpen",
  )
  const history = useHistory()
  const [workspaceInvitesCreate, { loading }] = useMutation<
    PostSignup_WorkspaceInviteCreateMutation,
    PostSignup_WorkspaceInviteCreateMutationVariables
  >(WORKSPACE_INVITE_CREATE)
  const {
    control,
    handleSubmit,
    register,
    setError,
    clearErrors,
    reset,
    setFocus,
    getValues,
    watch,
    formState: { errors },
  } = useForm<WorkspaceInviteFormValues>({
    defaultValues: {
      workspaceInvites: Array(3).fill({ email: "" }),
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: "workspaceInvites",
  })

  useEffect(() => {
    // Do not focus on mobile
    if (window.innerWidth > 1080) {
      setFocus("workspaceInvites.0.email")
    }
  }, [setFocus])

  const onComplete = () => {
    const redirectTo = sessionStorage.getItem("signupRedirectTo")
    if (redirectTo) {
      sessionStorage.removeItem("signupRedirectTo")
      history.push(redirectTo)
    } else {
      history.push(generateRealmPath("plus", "/send"))
    }
  }

  const onSubmit: SubmitHandler<WorkspaceInviteFormValues> = async ({
    workspaceInvites,
  }) => {
    if (!currentWorkspace) {
      // This should never happen as the button is disabled if the workspace isn't available yet.
      alert("An unknown error occurred.")
      return
    }

    const res = await workspaceInvitesCreate({
      variables: { workspaceId: currentWorkspace.id, workspaceInvites },
      refetchQueries: [
        {
          query: WorkspaceDocument,
          variables: { id: currentWorkspace.id },
        },
      ],
    })

    const resData = res.data?.workspaceInviteCreate
    if (resData?.ok) {
      if (resData.errors && resData.errors.length > 0) {
        // Replace the array inputs with the errored inputs.
        reset({
          workspaceInvites: resData.errors.map((error) => {
            return {
              email: error.email || "",
            }
          }),
        })

        // Clear the existing errors, then set new errors by index.
        // We use the email as the 'single error field' for the row.
        resData.errors.forEach((err, i) => {
          if (err.error) {
            setError(
              `workspaceInvites.${i}.email` as "workspaceInvites.0.email",
              {
                type: "manual",
                message: err.error,
              },
            )
          }
        })
      } else {
        const inviteCount = getNonEmptyCount(workspaceInvites)

        if (inviteCount > 0) {
          gtmEvent("Business_Onboarding_Invite_Submit")
          track("Business - Post-Signup - Invite - Submit", {
            inviteCount,
          })
        }

        // No errors.
        // Reset the form to the original state.
        clearErrors()
        onComplete()
      }
    } else {
      alert("An unknown error occurred.")
    }
  }

  const addInvitation = useCallback(() => {
    append([{ email: "" }], { shouldFocus: false })
  }, [append])

  const emailFields = watch("workspaceInvites")

  // Check if all fields are filled and add a new one if so
  // This needs to use useDeepCompareMemoizeEffect because react-hook-form's
  // watch returns the same object reference all the time so you can't use
  // useEffect.
  useDeepCompareMemoizeEffect(() => {
    const allFilled = emailFields.every((field) => field.email !== "")
    if (allFilled) {
      addInvitation()
    }
  }, [emailFields, addInvitation])

  const showPromo = !currentUser?.hasPaidPlan

  useEffect(() => {
    // If the user has a cart, set a local storage key to show the welcome
    // message variant when a cart was present when onboarding started.
    if (cartQuantity > 0) {
      window.localStorage.setItem(
        ONBOARDING_SHOW_WELCOME_WITH_CART_LOCAL_STORAGE_KEY,
        ONBOARDING_SHOW_WELCOME_WITH_CART_TRUE_VALUE,
      )
    }
  }, [cartQuantity])

  return (
    <div tw="fixed inset-0 bg-white overflow-y-auto z-[100]">
      <GradientBackground visible={true} gradientStartPercent={25} />
      <div tw="relative z-[10]">
        <Container>
          <div tw="flex flex-row items-start px-5 pb-6 pt-8">
            <div tw="flex-1 hidden md:block"></div>
            <div tw="flex-1 flex flex-row md:justify-center">
              <img src={Logo} alt="Goody" />
            </div>
            <div tw="flex-1 flex flex-row justify-end">
              <button
                tw="flex flex-row items-center gap-2 text-gray-450 md:text-xl p-2 px-2 md:px-4 rounded-xl hover:bg-gray-150 active:(bg-gray-200 scale-95) transition-all"
                onClick={() => {
                  onComplete()
                  gtmEvent("Business_Onboarding_Invite_Skip")
                }}
              >
                Skip this step
                <ChevronRight />
              </button>
            </div>
          </div>

          <div tw="mt-6 md:mt-12 mx-5 mb-48">
            <div tw="max-w-[928px] rounded-2xl border border-gray-150 bg-white shadow-card mx-auto flex flex-row items-stretch">
              <div tw="w-full md:w-[52%] p-8 md:p-12">
                {showPromo && (
                  <div tw="md:text-lg text-primary-new-600">
                    Invite your team
                  </div>
                )}
                <div tw="pt-4 font-reckless-neue tracking-[-0.72px] text-2xl md:text-4xl">
                  {showPromo
                    ? "Earn $50 for each team member you invite"
                    : "Invite your team"}
                </div>
                <div tw="pt-5 text-gray-500">
                  {showPromo ? (
                    <>
                      Credit is added after your colleague signs up and sends
                      their first gift.{" "}
                      <a
                        href="https://www.ongoody.com/referral-terms"
                        tw="text-gray-400 hover:text-gray-500"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms
                      </a>
                    </>
                  ) : (
                    "Workspace members can see each other’s gift activity, track gifts, and more."
                  )}
                </div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  tw="mt-10 max-w-[400px]"
                >
                  <div tw="flex flex-col gap-3">
                    {fields.map((_, index) => (
                      <Field
                        index={index}
                        error={
                          errors?.workspaceInvites?.[index]?.email?.message
                        }
                        register={register}
                        key={index}
                      />
                    ))}
                  </div>
                  <div tw="flex items-center gap-4 mt-8">
                    <Button
                      variant="primary-new"
                      disabled={!currentWorkspace || loading}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Invite
                    </Button>
                    <Button
                      variant="plain"
                      onClick={() => {
                        onComplete()
                        gtmEvent("Business_Onboarding_Invite_Skip")
                      }}
                    >
                      Do this later
                    </Button>
                  </div>
                </form>
              </div>
              <SideImage tw="hidden md:block w-[48%]">
                <img
                  src={staticAssetUrl("static/web/onboarding/invite-gifts.png")}
                  width="374"
                  height="462"
                  tw="max-w-full mx-auto"
                />
                {showPromo && (
                  <div tw="pt-6 font-medium text-gray-500 text-[15px]">
                    Get our most popular gifts with your credit
                  </div>
                )}
              </SideImage>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

const SideImage = styled.div`
  ${tw`bg-no-repeat bg-cover bg-center pt-10 pb-8 px-4 text-center rounded-tr-[15px] rounded-br-[15px]`};
  background-image: url(${gradientBg});
`

function getNonEmptyCount(workspaceInvites: WorkspaceInviteCreateInput[]) {
  return sumBy(workspaceInvites, (invite) => {
    return invite.email !== "" ? 1 : 0
  })
}

interface FieldProps {
  index: number
  error?: string
  register: UseFormRegister<WorkspaceInviteFormValues>
}

const Field = ({ index, error, register }: FieldProps) => (
  <>
    <div tw="flex flex-col">
      <TextInput
        {...register(
          `workspaceInvites.${index}.email` as "workspaceInvites.0.email",
        )}
        placeholder="Email"
        type="email"
        autoComplete="off"
        className="data-hj-suppress ph-no-capture fs-exclude"
        tw="py-3 focus:border-primary-new-400"
        css={[error && tw`rounded-b-none`]}
      />
      {error && <div tw="bg-gray-200 py-1 px-3 rounded-b-lg mb-1">{error}</div>}
    </div>
  </>
)

// See https://github.com/react-hook-form/react-hook-form/issues/7068#issuecomment-973167261
function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>(value)
  const signalRef = useRef<number>(0)

  if (!isEqual(value, ref.current)) {
    ref.current = cloneDeep(value)
    signalRef.current += 1
  }

  return useMemo(() => ref.current, [signalRef.current])
}

function useDeepCompareMemoizeEffect(
  callback: EffectCallback,
  dependencies: DependencyList,
): ReturnType<typeof useEffect> {
  return useEffect(callback, useDeepCompareMemoize(dependencies))
}

const WORKSPACE_INVITE_CREATE = gql`
  mutation PostSignup_WorkspaceInviteCreate(
    $workspaceId: ID!
    $workspaceInvites: [WorkspaceInviteCreateInput!]!
  ) {
    workspaceInviteCreate(
      workspaceId: $workspaceId
      workspaceInvites: $workspaceInvites
    ) {
      ok
      errors {
        email
        error
      }
    }
  }
`
